import React from "react";
import PageTemplate from "./base";
import imgHero from "../../assets/image/industries/profressional-services.jpeg";

const Page = () => {
  return (
    <>
      <PageTemplate
        seoTitle="Free Scheduling Software for Professional Services | Camelo"
        metaDescription="Let the Camelo scheduling software help you find the right professionals for your services. Start scheduling for free today!"
        header="Great services start with finding the right professionals."
        subHeader="Camelo is a better way to schedule, connect, and manage your team."
        imgUrl={imgHero}
      />
    </>
  );
};

export default Page;
